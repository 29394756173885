<template>
	<div class="cookie-container">
		<button class="btn btn-cookie" v-on:click="$CC.showPreferences()">COOKIES</button>
	</div>
</template>

<script setup lang="ts">

</script>
<style lang="scss">
@import "@/styles/variables.scss";

.cookie-container{
	position: absolute;
    bottom: 0px;
    left: 10px;
    z-index: 100;

	.btn-cookie{
		border-bottom-left-radius: 0px;
		border-bottom-right-radius: 0px;
		background-color: $color-primary;
		color: $text-color-dark !important;
		height: 32px;
		padding: 0px 15px;

		&:hover{
			background-color: $color-secondary !important;
		}
	}
}
</style>
