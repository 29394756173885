import { optIn, optOut, bootstrap } from "vue-gtag"
import { Constants } from "../classes/constants.ts"
/**
 * @type {import("vanilla-cookieconsent").CookieConsentConfig}
 */

const config = {
  guiOptions: {
    consentModal: {
      layout: "box inline",
      position: "bottom right",
    },
    preferencesModal: {
      layout: "box",
    },
  },

  onFirstConsent: ({cookie}) => {
    console.log("onFirstAction fired");
    if (Constants.googleAnalyticsId){
      if (cookie.categories.includes("analytics")){
        bootstrap().then(() => {  optIn();  });
      } else {
        optOut();
      }
    }
  },

  onConsent: ({cookie}) => {
    console.log("onConsent fired ...");
    if (Constants.googleAnalyticsId){
      if (cookie.categories.includes("analytics")){
        bootstrap().then(() => {  optIn(); });
      } else {
        optOut();
      }
    }
  },

  onChange: ({cookie}) => {
    console.log("onChange fired ...");
    if (Constants.googleAnalyticsId){
      if (cookie.categories.includes("analytics")){
        bootstrap().then(() => {  optIn(); });
      } else {
        optOut();
      }
    }
  },

  categories: {
    necessary: {
      readOnly: true,
      enabled: true,
    },
    analytics: {
      autoClear: {
        cookies: [
          {
            name: /^(_ga|_gid)/,
          },
        ],
      },
    },
  },

  language: {
    default: "en",

    translations: {
      en: {
        consentModal: {
          title: "Manage consent",
          description:
            "To provide the best experiences, we use technologies like cookies to store and/or access device information. Consenting to these technologies will allow us to process data such as browsing behavior or unique IDs on this site. Not consenting or withdrawing consent, may adversely affect certain features and functions.",
          acceptAllBtn: "Accept",
          acceptNecessaryBtn: "Reject",
          showPreferencesBtn: "Manage preferences",
          closeIconLabel: "Reject all and close",
        },
        preferencesModal: {
          title: "Cookie preferences",
          acceptAllBtn: "Accept all",
          acceptNecessaryBtn: "Reject all",
          savePreferencesBtn: "Save preferences",
          sections: [
            {
              title: "Cookie usage",
              description:
                "To provide the best experiences, we use technologies like cookies to store and/or access device information. Consenting to these technologies will allow us to process data such as browsing behavior or unique IDs on this site. Not consenting or withdrawing consent, may adversely affect certain features and functions.",
            },
            {
              title: "Functional",
              description:
                "The technical storage or access is strictly necessary for the legitimate purpose of enabling the use of a specific service explicitly requested by the subscriber or user, or for the sole purpose of carrying out the transmission of a communication over an electronic communications network.",
              linkedCategory: "necessary",
            },
            {
              title: "Marketing",
              description:
                "The technical storage or access is required to create user profiles to send advertising, or to track the user on a website or across several websites for similar marketing purposes.",
              linkedCategory: "analytics",
              cookieTable: {
                headers: {
                  name: "Cookie",
                  domain: "Domain",
                  desc: "Description",
                },
              },
            },
            {
              title: "More information",
              description:
                "For any queries in relation to our policy on cookies and your choices, please contact us.",
            },
          ],
        },
      },
    },
  },
};

export default config;
