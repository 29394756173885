import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";
import * as Sentry from "@sentry/vue";
import { Constants } from "./classes/constants";
import CookieConsentVue from "./plugins/cookieconsentvue.js";
import VueGtag from "vue-gtag";

const pinia = createPinia();
const app = createApp(App);
app.use(CookieConsentVue)

if (Constants.googleAnalyticsId)
{
	app.use(VueGtag, {
		config: { id: Constants.googleAnalyticsId },
		bootstrap: false
	}, router);
}

if(Constants.sentryDsn){
	Sentry.init({
		app,
		dsn: Constants.sentryDsn,
		integrations: [
			new Sentry.BrowserTracing({
				// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
				tracePropagationTargets: ["localhost", /^https:\/\/cloud\.3dsurvey.com/, /^https:\/\/development.cloud\.3dsurvey.com/],
				routingInstrumentation: Sentry.vueRouterInstrumentation(router),
			}),
			new Sentry.Replay(),
		],
		// Performance Monitoring
		tracesSampleRate: 1.0, // Capture 100% of the transactions
		// Session Replay
		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	});
}
  

app.use(pinia);
app.use(router);
app.mount("#app");
